
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    tableData: {
      type: Array as PropType<object[]>,
      default: () => []
    }
  }
});
