<template>
  <div class="modal fade" id="shifts_modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog mw-650px">
      <div class="modal-content">
        <div class="modal-header pb-0 border-0 justify-content-end">
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
        </div>
        <div class="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
          <div class="text-center mb-13">
            <h1 class="mb-3">View shifts</h1>
            <div class="text-gray-400 fw-bold fs-5">
              Showing user's shifts , duration and position
            </div>
          </div>
          <LoadingSpinner v-if="loading"></LoadingSpinner>
          <div class="mb-15" v-else>
            <div class="mh-375px scroll-y me-n7 pe-7">
              <template v-for="(shift, index) in userShifts" :key="index">
                <div
                  class="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed"
                >
                  <div class="d-flex align-items-center">
                    <div class="mx-3">
                      <router-link
                        :to="`/jobs/${shift.job.id}/shifts`"
                        class="badge rounded-pill bg-success fs-6 fw-bold mb-2"
                      >
                        {{ shift.job.name }}
                      </router-link>
                      <span
                        class="d-flex align-items-center fs-5 badge rounded-pill bg-success "
                      >
                        {{ shift.start }} - {{ shift.end }}
                      </span>
                      <span
                        class="badge rounded-pill bg-primary fs-6 fw-bold mt-2"
                      >
                        {{ shift.skill }}
                      </span>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="text-end">
                      <div
                        class="fs-5 fw-bolder text-dark badge rounded-pill bg-light"
                      >
                        {{ shift.duration }} hrs
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";

export default defineComponent({
  name: "shiftsModal",
  components: {
    LoadingSpinner
  },
  props: {
    loading: Boolean,
    userShifts: Array
  },
  watch: {
    userShifts() {
      console.log(this.userShifts)
    }
  }
});
</script>