<template>
  <!--begin::countriesIndex-->
  <div class="row gy-5 g-xl-8">
    <SalaryReportsList
      widget-classes="card-xxl-stretch mb-5 mb-xl-8"
    ></SalaryReportsList>
  </div>
  <!--end::countriesIndex-->
</template>

<script lang="js">
import { defineComponent, onMounted } from "vue";
import SalaryReportsList from "@/components/payments/SalaryReportsIndex.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "SalaryReportsView",
  components: {
    SalaryReportsList
  },
  mounted() {
    setCurrentPageTitle("Salary Reports");
  }
});
</script>
