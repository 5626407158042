
import { defineComponent } from "vue";
import SearchField from "@/components/general/SearchField.vue";
import SalaryReportsDataTable from "@/components/payments/tables/SalaryReportsDataTable.vue";
import { mapActions, mapGetters } from "vuex";
import MissingRatesModal from "@/components/payments/misc/MissingRatesModal.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import ShiftsModal from "@/components/general/ShiftsModal.vue";
import * as helpers from "@/components/payments/misc/helpers";
import Swal from "sweetalert2";
import { Modal } from "bootstrap";
import moment from "moment";
import MissingReportsModal from "@/components/payments/misc/MissingReportsModal.vue";
import PayModal, {
  PayModalData
} from "@/components/payments/misc/PayModal.vue";
import ApiService from "@/core/services/ApiService";
import SalaryReportsSummary from "@/components/salary-reports/SalaryReportSummary.vue";

export default defineComponent({
  name: "SalaryReportsIndex",
  components: {
    SalaryReportsSummary,
    PayModal,
    MissingReportsModal,
    SearchField,
    SalaryReportsDataTable,
    LoadingSpinner,
    Pagination,
    MissingRatesModal,
    ShiftsModal
  },
  data() {
    return {
      date: [] as string[],
      showTaxIdWorkers: false,
      summaryKey: 0,
      missingReportsShifts: [],
      isFromPayModal: false,
      modalkey: 0,
      progressBar: 0,
      pagination: {
        currentPage: 1,
        lastPage: 1
      },
      interval: undefined as any,
      isGeneratingReports: false,
      modal: null as Modal,
      missingRates: {},
      tempUserId: null,
      search: ""
    };
  },
  methods: {
    ...mapActions("SalaryReportsModule", [
      "fetchData",
      "resetState",
      "setCurrentPage",
      "setSearchParams",
      "setTaxIdFilter",
      "fetchSkillsForMissingRates",
      "setSearchByDate",
      "resetPageNumber",
      "downloadAllReports",
      "getProgress",
      "downloadPaySlip",
      "fetchShiftsByID",
      "fetchSummary"
    ]),
    ...mapActions("ListsModule", ["fetchSkills", "fetchBranches"]),
    changeCurrentPage(pageNumber) {
      this.setCurrentPage(pageNumber);
      this.fetchData();
    },
    changeSearch(userInput) {
      this.setSearchParams(userInput);
      this.resetPageNumber();
      this.search = userInput;
      this.summaryKey++;
      this.fetchData();
    },
    hideModal() {
      this.modal.hide();
    },
    setModalState(error, userId) {
      this.modalkey++;
      this.modal = null;
      this.missingRates = error;
      this.tempUserId = userId;
    },
    buildErrorString(error): string {
      let errorString = "" as string;
      const subSkills = this.skills.map(skill => skill.children).flat();
      Object.keys(error).forEach(branchID => {
        error[branchID].forEach(skillID => {
          const skill = subSkills.find(skill => skill.id == skillID);
          const branch = this.branches.find(branch => branch.id == branchID);
          errorString += `<span class="fs-5 fw-bold">${
            skill ? skill.name : ""
          } - ${branch ? branch.name : ""} </span></br>`;
        });
      });
      return errorString;
    },
    async handleAddRatesModal(error, userId) {
      this.setModalState(error, userId);
      await this.$nextTick();
      this.modal = new Modal(document.getElementById("missing-rates-modal"));
      this.modal.show();
    },
    handleDownloadAll() {
      const query = {
        period: this.date,
        taxID: this.showTaxIdWorkers
      };
      this.isGeneratingReports = true;
      this.downloadAllReports(query)
        .then(response => {
          this.handleProgressBar(response);
        })
        .catch(() => {
          this.clearLoadingBar();
        });
    },
    handleProgressBar(id: number) {
      this.interval = setInterval(() => {
        this.getProgress(id)
          .then(result => {
            this.handleUI(result);
          })
          .catch(() => {
            this.clearLoadingBar();
          });
      }, 1000);
    },
    handleUI(data) {
      if (data.status == "failed") {
        helpers.fireDownloadAllError(data.result);
        this.clearLoadingBar();
      } else if (data.status == "finished") {
        this.progressBar = data.progress;
        this.clearLoadingBar();
        this.handleS3Download(data.result);
      } else {
        this.progressBar = data.progress;
      }
    },
    clearLoadingBar() {
      this.isGeneratingReports = false;
      this.progressBar = 0;
      window.clearInterval(this.interval);
    },
    handleMissingRatesModalClose() {
      if (
        this.isFromPayModal &&
        !this.isAwaitingResponse.ids.includes(this.tempUserId)
      ) {
        this.isFromPayModal = false;
        this.getSalaryModal.toggleLoading(false);
      }
    },
    handleMissingRates(query, error) {
      Swal.fire({
        ...helpers.MissingRatesSwalOptions,
        html: this.buildErrorString(error.rates)
      }).then(result => {
        if (result.isConfirmed) {
          this.handleAddRatesModal(error.rates, query.userId);
          if (error.isModal) {
            this.isFromPayModal = true;
          }
        } else if (result.isDenied) {
          this.downloadPaySlip({ ...query, replaceZero: 1 }).then(response => {
            if (
              response &&
              response.data &&
              response.data.type == "MissingRates"
            ) {
              this.handleMissingRates(query, response.data);
            } else {
              if (error.isModal) {
                this.getSalaryModal.toggleLoading(false);
              }
              this.handleS3Download(response.data.url);
            }
          });
        } else if (result.isDismissed) {
          if (error.isModal) {
            this.getSalaryModal.toggleLoading(false);
          }
        }
      });
    },
    handleS3Download(url) {
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
    },
    exportPaySlip(id) {
      const query = {
        userId: id,
        fromDate: this.date[0],
        toDate: this.date[1]
      };
      this.downloadPaySlip(query).then(response => {
        if (response && response.data && response.data.type == "MissingRates") {
          this.handleMissingRates(query, response.data);
        } else {
          if (0 < response.data.missing_report_shifts.length) {
            const modal = document.getElementById("missing-reports-modal");
            if (modal) {
              this.missingReportsShifts = response.data.missing_report_shifts;
              const bootStrapObject = new Modal(modal) as Modal;
              bootStrapObject.show();
            }
          }
          if (this.isFromPayModal) {
            this.isFromPayModal = false;
            this.getSalaryModal.toggleLoading(false);
          }
          this.handleS3Download(response.data.url);
        }
      });
    },
    handleUseOnce(data) {
      const query = {
        userId: this.tempUserId,
        fromDate: this.date[0],
        toDate: this.date[1],
        tempRates: data
      };
      this.downloadPaySlip(query);
      this.hideModal();
    },
    handleOpenPayModal(data) {
      const payload = {
        userId: data.id,
        fromDate: this.date[0],
        toDate: this.date[1]
      } as PayModalData;
      this.getSalaryModal.toggleModal(true, payload);
    },
    handleSaveRates() {
      this.exportPaySlip(this.tempUserId);
      this.hideModal();
    },
    openUserShifts(id) {
      this.fetchShiftsByID(id);
    }
  },
  computed: {
    ...mapGetters("ListsModule", ["skills", "branches"]),
    ...mapGetters("SalaryReportsModule", [
      "getData",
      "loading",
      "getPagination",
      "getShiftsData",
      "getShiftsLoading",
      "isAwaitingResponse"
    ]),
    getSalaryModal(): InstanceType<typeof PayModal> {
      return this.$refs.paySalaryModal as InstanceType<typeof PayModal>;
    }
  },
  watch: {
    showTaxIdWorkers() {
      this.setTaxIdFilter(this.showTaxIdWorkers);
      this.resetPageNumber();
      this.fetchData();
      this.summaryKey++;
    },
    date() {
      this.setSearchByDate(this.date);
      this.resetPageNumber();
      this.fetchData();
      this.summaryKey++;
    }
  },
  mounted() {
    this.fetchSkills();
    this.fetchBranches();
    const now = moment().format("DD");
    if (parseInt(now) > 20) {
      //If day now is bigger than 20
      const date = [] as string[];
      date.push(
        moment() //Get current date
          .subtract(1, "month") //Subtract 1 month from it
          .startOf("month") //we get the start the last month
          .add(20, "d") //add 20 days to it so it will be 21
          .format("DD/MM/YYYY") //format to Api accepted format
      );
      date.push(
        moment() //Get current date
          .startOf("month") //Take the start of the month
          .add(19, "d") //Add 19 days so it will be 20 this month
          .format("DD/MM/YYYY") //Format to Api accepted format
      );
      this.date = date;
    } else {
      //If its not the 20th
      const date = [] as string[];
      date.push(
        moment() //Get current date
          .subtract(2, "months") //Subtract 2 month from it
          .startOf("month") //we get the start the month
          .add(20, "d") //add 20 days to it so it will be the 21st of the month before the last
          .format("DD/MM/YYYY") //format to Api accepted format
      );
      date.push(
        moment() //Get current date
          .subtract(1, "month") //Subtract 1 month from it
          .startOf("month") //Take the start of the month
          .add(19, "d") //Add 19 days so it will be 20 last month
          .format("DD/MM/YYYY") //Format to Api accepted format
      );
      this.date = date;
    }
  },
  updated() {
    this.pagination = this.getPagination;
  },
  unmounted() {
    this.resetState();
  }
});
