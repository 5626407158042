
import SalaryExportsHistoryModal from "@/components/payments/misc/SalaryExportsHistoryModal.vue";
import { defineComponent, computed, ref, nextTick } from "vue";
import { Modal } from "bootstrap";
import { useStore } from "vuex";

export default defineComponent({
  name: "salaryReportsDataTable",
  components: {
    SalaryExportsHistoryModal
  },
  props: {
    tableData: Object
  },
  setup(props, { emit }) {
    const store = useStore();
    const modalKey = ref<number>(0);
    const modal = ref<Modal | null>(null);
    const userID = ref<number | null>(null);
    const isAwaitingResponse = computed(
      () => store.getters["SalaryReportsModule/isAwaitingResponse"]
    );
    const calculateApprovedHours = (value: string): string => {
      const integer = parseInt(value);
      if (value) {
        const double = Math.round((integer / 60) * 100 + Number.EPSILON) / 100;
        const whole = Math.floor(double);
        const minutes = Math.round((double - whole) * 60);
        return `${whole} hrs ${minutes != 0 ? `${minutes} min` : ""}`;
      }
      return "0 hrs";
    };
    const downloadButtonClick = (userID: number) => {
      emit("download", userID);
    };

    const shiftsButtonClick = (userID: number) => {
      emit("shifts", userID);
    };

    const openPayModal = (eventData: object) => {
      emit("openPayModal", eventData);
    };

    const handlePreviousReports = async (eventValue: number) => {
      modalKey.value++;
      userID.value = eventValue;
      await nextTick().then(() => {
        modal.value = new Modal(
          document.getElementById("salary-exports-history-modal")
        );
        modal.value.show();
      });
    };
    return {
      calculateApprovedHours,
      handlePreviousReports,
      downloadButtonClick,
      isAwaitingResponse,
      shiftsButtonClick,
      openPayModal,
      modalKey,
      userID
    };
  }
});
