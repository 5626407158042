
import { defineComponent, PropType } from "vue";

interface MissingShift {
  id: number;
  job_id: number;
  job_name: string;
  label: string;
}

export default defineComponent({
  name: "missing-reports-modal",
  props: {
    shifts: {
      type: Array as PropType<MissingShift[]>,
      default: () => []
    }
  }
});
