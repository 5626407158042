
import { defineComponent, ref, onMounted } from "vue";
import { Modal } from "bootstrap";
import { useStore } from "vuex";
import Swal from "sweetalert2";

export interface PayModalData {
  userId: number;
  fromDate: string;
  toDate: string;
}

export default defineComponent({
  name: "pay-modal",
  setup: function(props, { emit }) {
    const modal = ref<Modal | null>(null);
    const store = useStore();
    const modalState = ref<PayModalData | null>(null);
    const loading = ref<boolean>(false);

    onMounted((): void => {
      const element = document.getElementById("pay-salary-modal");
      if (element) {
        modal.value = new Modal(element);
        element.addEventListener("hidden.bs.modal", () => {
          modalState.value = null;
          loading.value = false;
        });
      }
    });

    function toggleLoading(value: boolean): void {
      loading.value = value;
    }

    function previewSalaryReport(): void {
      toggleLoading(true);
      store
        .dispatch("SalaryReportsModule/downloadPaySlip", modalState.value)
        .then(response => {
          if (response && response.data) {
            if (response.data.type == "MissingRates") {
              emit("handleMissingRates", {
                response: { isModal: true, ...response.data },
                query: modalState.value
              });
            } else {
              toggleLoading(false);
              const link = document.createElement("a");
              link.href = response.data.url;
              document.body.appendChild(link);
              link.click();
            }
          }
        })
        .catch(err => {
          Swal.fire({
            title: err,
            toast: true,
            icon: "error",
            showCancelButton: false,
            timer: 1200
          });
          loading.value = false;
        });
    }

    function toggleModal(toggle: boolean, data: PayModalData | null) {
      if (modal.value) {
        if (toggle) {
          modal.value.show();
          if (data) {
            modalState.value = data;
          }
        } else {
          modal.value.hide();
        }
      }
    }

    function handlePayButton(): void {
      loading.value = true;
      store
        .dispatch("SalaryReportsModule/handlePayButton", modalState.value)
        .then(() => {
          Swal.fire({
            toast: true,
            icon: "success",
            title: "Successfully marked applications as payed",
            timer: 1000,
            showCancelButton: false,
            showConfirmButton: false,
            showDenyButton: false
          });
          toggleModal(false, null);
          loading.value = false;
        })
        .catch(() => {
          loading.value = false;
        });
    }

    return {
      previewSalaryReport,
      handlePayButton,
      toggleLoading,
      toggleModal,
      loading
    };
  }
});
