import Swal from "sweetalert2";

const MissingRatesSwalOptions = {
  title: "There are missing rates",
  showDenyButton: true,
  showCancelButton: true,
  confirmButtonText: "Add rates",
  denyButtonColor: "#0095E8",
  cancelButtonColor: "#E4E6EF",
  confirmButtonColor: "#5014D0",
  denyButtonText: `Replace with 0`
};

const fireDownloadAllError = (errorString: string) => {
  Swal.fire({
    icon: 'error',
    title: errorString,
    timer: 1000,
    showConfirmButton: false
  })
}

export { MissingRatesSwalOptions, fireDownloadAllError };
