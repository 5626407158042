
import {
  defineComponent,
  onMounted,
  PropType,
  computed,
  reactive,
  ref
} from "vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import SalaryExportsHistoryDataTable from "@/components/payments/misc/SalaryExportsHistoryDataTable.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "salary-exports-history-modal",
  components: {
    SalaryExportsHistoryDataTable,
    Pagination,
    LoadingSpinner
  },
  props: {
    userID: {
      type: Number as PropType<number | null>,
      default: null
    }
  },
  setup(props) {
    const store = useStore();
    const loading = ref<boolean>(false);
    const salaryReportsHistory = computed(
      () => store.getters["SalaryReportsModule/getSalaryReportsHistory"]
    );
    const paginationState = computed(
      () => store.getters["SalaryReportsModule/getExportHistoryPagination"]
    );
    const pagination = reactive({
      currentPage: 1,
      totalPages: 1
    });
    const updatePagination = () => {
      pagination.totalPages = paginationState.value.lastPage;
      pagination.currentPage = paginationState.value.currentPage;
    };
    const fetchExportHistory = () => {
      loading.value = true;
      store
        .dispatch("SalaryReportsModule/fetchExportHistory", {
          userID: props.userID,
          page: pagination.currentPage
        })
        .then(() => {
          loading.value = false;
          updatePagination();
        });
    };
    const handlePageChange = (page: number) => {
      pagination.currentPage = page;
      fetchExportHistory();
    };

    onMounted(() => {
      if (props.userID) fetchExportHistory();
    });

    return {
      salaryReportsHistory,
      handlePageChange,
      loading,
      pagination
    };
  }
});
