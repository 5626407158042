
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref
} from "vue";
import { useStore } from "vuex";
import CountUp from "vue-countup-v3";
import ArrowUpIcon from "@/components/Icons/ArrowUp.vue";
import ArrowDownIcon from "@/components/Icons/ArrowDown.vue";

interface SummaryDataResult {
  result: number;
  trend: null | "up" | "down";
}

interface SalaryReportSummary {
  users: SalaryReportSummary | null;
  salaries: SalaryReportSummary | null;
  hours: SalaryReportSummary | null;
}

export default defineComponent({
  name: "salary-reports-summary",
  components: { ArrowDownIcon, ArrowUpIcon, CountUp },
  props: {
    date: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    showTaxId: {
      type: Boolean as PropType<boolean>,
      default: () => false
    },
    search: {
      type: String as PropType<string>,
      default: () => ""
    },
  },
  setup: function(props) {
    const store = useStore();
    const loading = ref<boolean>(false);

    function setLoading(value: boolean): void {
      loading.value = value;
    }

    function fetchSummary(): void {
      if (!props.date || !props.date.length) {
        store.dispatch("SalaryReportsModule/clearSummary");
        return;
      }
      setLoading(true);
      store
        .dispatch("SalaryReportsModule/fetchSummary", {
          date: props.date,
          taxId: props.showTaxId,
          search: props.search.trim() == "" ? null : props.search
        })
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }

    const data = computed(() => store.getters["SalaryReportsModule/summary"]);

    onMounted((): void => {
      fetchSummary();
    });

    onUnmounted((): void => {
      store.dispatch("SalaryReportsModule/clearSummary");
    });

    return {
      loading,
      data
    };
  }
});
