
import { defineComponent } from "vue";

export default defineComponent({
  name: "Pagination",
  components: {},
  props: {
    currentPage: {
      type: Number
    },
    totalPages: {
      type: Number
    },
    maxPages: {
      type: Number,
      default: 8
    }
  },
  data() {
    return {
      AllpageCollection: [1],
      shownPageCollection: [1],
      areThereLessThanEightPages: true,
      selectedPage: 1,
      lastPageOfCurrentShownCollection: 1
    };
  },
  methods: {
    changeCurrentPage(pageNumber) {
      this.selectedPage = pageNumber;
      this.$emit("changeCurrentPage", this.selectedPage);
    },
    nextButtonClicked() {
      if (this.totalPages != undefined && this.selectedPage < this.totalPages) {
        this.selectedPage++;
        this.changeCurrentPage(this.selectedPage);
        if (this.selectedPage > this.lastPageOfCurrentShownCollection) {
          this.shownPageCollection = this.AllpageCollection.filter(
            n =>
              n >= this.selectedPage && n <= this.selectedPage + this.maxPages
          );
        }
      }
    },
    backButtonClicked() {
      if (this.selectedPage > 1) {
        this.selectedPage--;
        this.changeCurrentPage(this.selectedPage);
        if (this.selectedPage < this.shownPageCollection[0]) {
          this.shownPageCollection = this.AllpageCollection.filter(
            n =>
              n >= this.selectedPage - this.maxPages && n <= this.selectedPage
          );
        }
      }
    }
  },
  watch: {
    totalPages() {
      this.selectedPage = 1;
      this.AllpageCollection = [1];
      if (this.totalPages !== undefined && this.totalPages !== 1) {
        for (let i = 1; i < this.totalPages; i++) {
          this.AllpageCollection.push(i + 1);
        }
      }
      if (this.AllpageCollection.length > this.maxPages) {
        this.areThereLessThanEightPages = false;
        this.shownPageCollection = this.AllpageCollection.filter(
          n => n >= 1 && n <= this.maxPages
        );
      } else {
        this.areThereLessThanEightPages = true;
        this.shownPageCollection = this.AllpageCollection;
      }
    },
    shownPageCollection() {
      this.lastPageOfCurrentShownCollection = this.shownPageCollection[
        this.shownPageCollection.length - 1
      ];
    }
  },
  computed: {
    shouldNextArrowBeVisible() {
      if (
        this.totalPages != undefined &&
        this.lastPageOfCurrentShownCollection == this.totalPages
      )
        return false;
      else return true;
    },
    shouldBackArrowBeVisible() {
      if (this.shownPageCollection[0] == 1) return false;
      else return true;
    }
  }
});
