
import {
  defineComponent,
  onMounted,
  reactive,
  PropType,
  computed,
  ref
} from "vue";
import * as types from "@/components/worker-rates/misc/types";
import * as helpers from "@/components/payments/misc/helpers";
import RatesDataTable from "@/components/worker-rates/Data-table.vue";
import Swal from "sweetalert2";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import { useStore } from "vuex";
import _ from "lodash";

export default defineComponent({
  name: "missing-rates-modal",
  components: {
    RatesDataTable,
    LoadingSpinner
  },
  props: {
    missingRates: {
      type: Array as PropType<object[]>,
      default: () => []
    }
  },
  emits: ["modal-close-end", "useOnce", "savedRates"],
  setup(props, { emit }) {
    const store = useStore();
    const model = reactive({});
    const missingSkills = ref([]);
    const isSavingRates = ref<boolean>(false);
    const hasError = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const branches = computed(() => store.getters["ListsModule/branches"]);

    const buildModel = keys => {
      keys.forEach(key => {
        model[key] = {};
      });
    };
    onMounted(() => {
      const missingRatesModal = document.getElementById("missing-rates-modal");
      if (missingRatesModal) {
        missingRatesModal.addEventListener("hidden.bs.modal", () => {
          emit("modal-close-end");
        });
      }
      if (!_.isEmpty(props.missingRates)) {
        loading.value = true;
        store
          .dispatch(
            "SalaryReportsModule/fetchSkillsForMissingRates",
            props.missingRates
          )
          .then(response => {
            buildModel(Object.keys(response.data));
            missingSkills.value = response.data;
            loading.value = false;
          })
          .catch(() => {
            hasError.value = true;
            loading.value = false;
          });
      }
    });
    const handleTableInput = data => {
      model[data.branchId] = data.values;
    };

    const buildPayload = (): object[] | boolean => {
      const payload = [] as object[];
      Object.keys(model).forEach(key => {
        payload.push(Object.values(model[key]));
      });
      return payload.flat();
    };

    const hasValidData = (): boolean => {
      const values = [] as any[];
      Object.keys(model).forEach(key => {
        values.push(Object.values(model[key]).map((a: any) => a.base_rate));
      });
      return !values.flat().includes("");
    };

    const notifyInvalidData = (isSaving: boolean) => {
      Swal.fire({
        title: "Base rate cannot be empty",
        timer: 1200,
        icon: "error"
      }).then(() => {
        if (isSaving) isSavingRates.value = false;
      });
    };

    const handleUseOnce = () => {
      if (!hasValidData()) {
        notifyInvalidData(false);
      } else {
        emit("useOnce", model);
      }
    };

    const handleSave = () => {
      const payload = buildPayload() as types.SkillRate[];
      isSavingRates.value = true;
      if (!hasValidData()) {
        notifyInvalidData(true);
      } else {
        store
          .dispatch("WorkerRatesModule/store", { rates: payload })
          .then(() => {
            isSavingRates.value = false;
            emit("savedRates");
          });
      }
    };

    const branchName = (id: string) => {
      const branch = branches.value.find(branch => branch.id == parseInt(id));
      return branch ? branch.name : "";
    };
    return {
      loading,
      isSavingRates,
      missingSkills,
      hasError,
      branchName,
      handleTableInput,
      handleSave,
      handleUseOnce
    };
  }
});
